import { Constants } from './../../core/data/constants';
import { Injectable } from '@angular/core';
import {jwtDecode} from  'jwt-decode';

@Injectable({
    providedIn: 'root'
})
export class StorageUtilsService {

    constructor() { }
    setAccessToken(token: any) {
        localStorage.setItem(Constants.accessToken, token);
    }

    getAccessToken(): string {
        return localStorage.getItem(Constants.accessToken) ?? '';
    }

    /** get decode access token */
    getDecodedAccessToken(): any {
        try {
            return jwtDecode(localStorage.getItem(Constants.accessToken));
        } catch (error) {
            return null;
        }
    }

    removeAccessToken() {
        localStorage.removeItem(Constants.accessToken);
    }

    setUser(user: any): void {
        localStorage.setItem(Constants.userKey, JSON.stringify(user));
    }

    getUser(): any {
        const itemString = localStorage.getItem(Constants.userKey);
        return itemString ? JSON.parse(itemString) : null;
    }

    removeUser(): void {
        localStorage.removeItem(Constants.userKey);
    }

    /** reset local storage */
    resetLocalStorage(): void{
        localStorage.clear();   
    }
}
